<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) Which of the following statements explains why <stemble-latex content="$\ce{NaHCO3}$" />,
        baking soda, can neutralize both acids and bases? Check all statements that apply.
      </p>

      <p v-for="option in options" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-2 mt-4">b) I think this because:</p>

      <p class="mb-n3">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Question515',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
        explainedResponse: null,
      },
      options: [
        {text: '$\\ce{NaHCO3}$ is used in baking', value: 'baking'},
        {text: '$\\ce{NaHCO3}$ is inexpensive', value: 'cheap'},
        {text: '$\\ce{HCO3-}$ reacts with bases to form carbonate and water', value: 'reactsBases'},
        {text: '$\\ce{HCO3-}$ reacts with acids to form carbonic acid', value: 'reactsAcids'},
        {text: '$\\ce{NaHCO3}$ is amphoteric', value: 'amphoteric'},
        {text: '$\\ce{NaHCO3}$ undergoes autoprotolysis', value: 'autoproto'},
      ],
    };
  },
};
</script>
